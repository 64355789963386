footer {
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 80px;
}

.footer-nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-left: 0;
	padding-right: 0;
	margin-top: 20px;
	margin-bottom: 20px;
}

.footer-nav li {
	display: inline;
	list-style-type: none;
	margin: 0;
}

.footer-nav li a,
.footer-nav li a:hover,
.footer-nav li a:focus,
.footer-nav li a.active {
	transition: 0.2s;
}

.footer-nav li a {
	color: #777;
	text-decoration: none;
	padding-left: 15px;
	padding-right: 15px;
}

.footer-nav li a:hover,
.footer-nav li a:focus {
	color:#222;
}

.darkmode .footer-nav li a:hover,
.darkmode .footer-nav li a:focus {
	color:#eaeaea;
}