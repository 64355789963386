 /* The switch - the box around the slider */
.theme-switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	margin-top: auto;
	margin-bottom: auto;
}

/* Hide default HTML checkbox */
.theme-switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 3px 8px 3px 12px;
	background-color: #F2B90F;
	-webkit-transition: .3s;
	transition: .3s;
}

.slider:after {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .3s;
	transition: .3s;
}

input:checked + .slider {
	background-color: #5b1c63;
}

input:checked + .slider:after {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
	background-color: #eaeaea;
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:after {
	border-radius: 50%;
}

@media (max-width: 449px) {
	.theme-switch {
		width: 62px;
	}
}

@media (max-width: 420px) {
	.theme-switch {
		width: 69px;
	}
}

@media (max-width: 400px) {
	.theme-switch {
		width: 77px;
	}
}