.project-detail {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding-left: 15px;
	padding-right: 15px;
	overflow: scroll;
}

.project-detail header {
	padding-left: 0;
	padding-right: 0;
}

.quick-home,
.quick-home a {
	position: fixed;
	top: 0;
	left: 0;
	right: 16px;
	bottom: 0;
	overflow: hidden;
}

.quick-home {
	background: rgba(0,0,0,0.5);
}

.project-display-wrap {
	width: 100%;
	max-width: 1200px;
	min-width: 325px;
	position: relative;
	margin-top: 100px;
	padding: 0px 50px 50px;
	background: white;
	border-radius: 10px;
	z-index: 10;
	transition: 0.1s;
}

.project-display-wrap header {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: nowrap;
	justify-content: space-between;
	position: sticky;
	top: 0px;
	background: white;
	padding-top: 50px;
	margin-bottom: 50px;
	border-bottom: 1px solid #222;
}

.darkmode .project-display-wrap,
.darkmode .project-display-wrap header {
	background-color: #222;
	transition: 0.1s; 
}

.darkmode .project-display-wrap header {
	border-bottom: 1px solid #eaeaea; 
}

.project-details {
	position: sticky;
	top: 50px;
	padding-bottom: 50px;
}

.info-display-content p {
	margin-bottom: 50px;
}

.content-wrap {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: nowrap;
}

.info-display {
	width: 30%;
	min-width: 300px;
}

.info-display header {
	align-items: flex-start;
}

.image-display {
	position: relative;
	width: 65%;
	margin-right: 5%;
	padding-top: 50px;
}

.image-display img {
	margin-bottom: 50px;
	border-radius: 10px;
	box-shadow: 0px 0px 30px 15px rgba(0,0,0,0.075);
}

.project-open {
	overflow: hidden;
}

.pagination {
	display: flex;
	flex: 0 0 100%;
	box-sizing: border-box;
	z-index: 100;
}

.pagination#desktop {
	width: 100%;
	position: sticky;
	top: 210px;
}

.pagination#desktop .pagination-wrap #close-btn\ start-fix,
.pagination#desktop .pagination-wrap #close-btn\ no-probs,
.pagination#desktop .pagination-wrap #close-btn\ end-fix {
	display: none;
}

.pagination#desktop .pagination-wrap #prev {
	margin-right: auto;
}

.pagination#desktop .pagination-wrap #next {
	margin-left: auto;
}

.pagination#mobile {
	display: none;
	position: fixed;
	padding: 10px 25px;
	bottom: 65px;
	left: 5%;
	right: 5%;
	margin-left: 20px;
	margin-right: 20px;
	border-radius: 30px;
	background-color: rgba(220, 220, 220, 0.92);
	box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.75);
	transition: 0.1s;
}

.darkmode .pagination#mobile {
	background-color: rgba(40, 40, 40, 0.95);
	transition: 0.1s;
}

.pagination a {
	text-decoration: none;
}

.pagination a:hover,
.pagination a:active {
	text-decoration: underline;
}

.pagination#mobile button {
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.darkmode .pagination#mobile button {
	color: white;
}

.pagination-wrap {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

@media (max-width: 1024px) {

	.project-display-wrap {
		margin-top: 50px;
		padding: 0px 25px 25px;
	}

	.project-display-wrap header {
		padding-top: 35px;
		margin-bottom: 35px;
	}

	.project-details {
		padding-bottom: 35px;
	}

	.content-wrap {
		flex-direction: column;
	}

	.info-display,
	.image-display {
		width: 100%;
	}

	.image-display {
		margin-right: 0;
	}

	.info-display-content p {
		margin-bottom: 0px;
	}

	.pagination#desktop {
		display: none;
	}

	.pagination#mobile {
		display: block; 
	}

	.pagination #close-btn\ start-fix,
	.pagination #close-btn\ end-fix {
		margin: auto;
	}

	.pagination #close-btn\ start-fix {
		transform: translateX(42.5%);
	}

	.pagination #close-btn\ end-fix {
		transform: translateX(-42.5%);
	}

	.quick-home,
	.quick-home a {
		right: 0px;
	}

}