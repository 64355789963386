header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
}

header h1 {
	margin-right: auto;
}

.header-nav {
	display:flex;
	align-items: center;
	padding-right: 40px;
}

.header-nav li {
	display: inline;
	list-style-type: none;
	margin: 0;
}

.header-nav li a,
.header-nav li a:hover,
.header-nav li a:focus,
.header-nav li a.active {
	transition: 0.2s;
}

.header-nav li a {
	color: #222;
	text-transform: uppercase;
	padding-left: 30px;
	padding-right: 0px;
}

.darkmode .header-nav li a {
	color: white;
}

.header-nav li a:hover,
.header-nav li a:focus {
	color:#777;
}

.header-nav li a.active {
	font-weight: bold;
	color: #222;
}

.mobile-nav-wrap {
	display: none;
}

.mobile-nav-button {
	min-width: 50%;
	font-size: calc(16px + 1.9vw);
	line-height: 1.2;
	letter-spacing: 0.125em;
	text-transform: uppercase;
	color: #222;
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding-left: 0;
	padding-bottom: 5px;
	padding-top: 5px;
	padding-right: 0;
	margin-right: 0;
	margin-left: 0;
	transition: 0.2s;
}

.darkmode .mobile-nav-button {
	color: white;
	transition: 0.1s;
}

@media (max-width: 1024px) {

	header .nav-wrap,
	.header-nav.mobile-nav.closed {
		display: none;
	}

	.mobile-nav-wrap,
	.header-nav.mobile-nav.open {
		display: block;
	}

	.mobile-nav-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: auto;
		width: calc(100% - 80px);
		position: fixed;
		bottom: 0;
		left: 0;
		padding-top: 5px;
		padding-bottom: 5px; 
		padding-left: 40px;
		padding-right: 40px;
		background-color: rgba(255, 255, 255, 0.98);
		border-top: 1px solid lightgray;
		box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.05);
		z-index: 100;
	}

	.darkmode .mobile-nav-wrap {
		background-color: rgba(0, 0, 0, 0.98);
		border-top: 1px solid #333;
		transition: 0.1s;
	}

	.toggle-nav-placement {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0.5;
	}

	.toggle-nav-placement.toggle-right {
		right: 40px;
	}

	.toggle-nav-placement.toggle-left {
		left: 40px;
	}

	.mobile-nav-wrap.nav-on-right .mobile-nav-button {
		margin-right: 0;
		margin-left: 50%;
		text-align: right;
		transition-property: margin-right, margin-left;
		transition: 0.2s;
	}

	.mobile-nav-wrap.nav-on-left .mobile-nav-button {
		margin-right: 50%;
		margin-left: 0;
		text-align: left;
		transition-property: margin-right, margin-left;
		transition: 0.2s;
	}

	.mobile-nav-wrap.nav-on-right .toggle-right {
		display: none;
	}

	.mobile-nav-wrap.nav-on-left .toggle-left {
		display: none;
	}

	.header-nav.mobile-nav {
		position: absolute;
		top: 0;
		transform: translateY(-100.25%);
		left: 0;
		right: 0;
		background-color: rgba(255,255,255,0.98);
		padding-left: 0;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 0;
		margin-top: 0;
		margin-bottom: 0;
		box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.05);
		transition: 0.1s;
	}

	.darkmode .header-nav.mobile-nav {
		background-color: rgba(0,0,0,0.98);
		transition: 0.1s;
	}

	.header-nav.mobile-nav li {
		display: block;
		margin-top: 10px;
		margin-bottom: 10px;
		padding: 12.5px 40px;
	}

	.header-nav.mobile-nav li a {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 5px;
		border-bottom: 2px solid #222;
		text-decoration: none;
	}

	.darkmode .header-nav.mobile-nav li a {
		color: #eaeaea;
		border-bottom: 2px solid white;
		transition: 0.1s;
	}

	.mobile-nav-wrap.nav-on-right .header-nav.mobile-nav {
		left: 50%;
		text-align: right;
	}

	.mobile-nav-wrap.nav-on-left .header-nav.mobile-nav {
		right: 50%;
		text-align: left;
	}

}