.form-wrap {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.form-wrap h4,
.form-wrap button {
	margin-bottom: 20px;
}

.form-input form,
.form-preview {
	display: flex;
	flex-direction: column;
}

.form-input input,
.form-input textarea {
	width: 100%;
	box-sizing: border-box;
	margin-top: 25px;
	margin-bottom: 25px;
	padding-bottom: 20px;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid #222;
	font-size: 1em;
	transition: 0.1s; 
}

.form-input input[type=submit] {
	color: #eaeaea;
	border: 2px solid #222;
	background-color: #222;
	padding-top: 10px;
	padding-bottom: 13px;
	transition: background-color 0.1s, border-color 0.1s;
}

.form-input input[type=submit]:hover {
	cursor: pointer;
}

.form-input input[type=submit]:active {
	background-color: #555;
	border-color: #444;
}

.darkmode .form-input input,
.darkmode .form-input textarea {
	color: #eaeaea;
	border-bottom: 2px solid #eaeaea;
	transition: 0.1s;
}

.darkmode .form-input input[type=submit] {
	color: #222;
	border: 2px solid #eaeaea;
	background-color: #eaeaea;
}

.darkmode .form-input input[type=submit]:active {
	background-color: #999;
	border-color: #aaa;
}

.form-input.preview-closed {
	width: 50%;
	transform: translateX(50%);
	transition: 0.25s ease 0.25s;
}

.form-input.preview-open {
	transform: translateX(0%);
	transition: transform 0.25s ease 0s;
	width: 47.5%;
}

.form-preview {
	width: 47.5%;
}

.form-preview.hidden {
	opacity: 0;
	transition: 0.25s;
}

.form-preview.showing {
	opacity: 1;
	transition: opacity 0.25s ease 0.25s;
}

.preview-contents {
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #eaeaea;
	border-radius: 10px;
	padding: 25px;
	background-color: white;
	box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.1);
	white-space: pre-wrap;
}

.darkmode .preview-contents {
	background-color: #000;
	border: 1px solid #212121;
}

.preview-entry {
	font-size: 1.25em;
	margin-bottom: 10px;
}

#preview-message {
	margin-top: 25px;
}

.error-report {
	color: red;
	margin-bottom: 40px;
}

.mail-server-status {
	opacity: 0.4;
}

.grecaptcha-badge {
	position: inherit!important;
}

.send-status-message {
	margin-bottom: 50px;
	margin-top: 20px;
}

@media (max-width: 1200px) {

	.form-wrap {
		flex-direction: column;
		align-items: center;
	}

	.form-input.preview-closed,
	.form-input.preview-open,
	.form-preview.hidden,
	.form-preview.showing {
		width: 100%;
		transform:translateX(0%);
	}

}