.filter,
.filter ul {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.filter {
	width: 100%;
	margin-bottom: 25px;
}

.filer p {
	margin-bottom: 0;
	margin-right: 10px;
}

.filter ul {
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.filter li {
	margin-right: 10px;
}

.bounce {
	animation:bouncy 4s linear;
	position:relative;
}

@keyframes bouncy {
	0%{top:0em}
	40%{top:0em}
	43%{top:-0.6em}
	46%{top:0em}
	48%{top:-0.3em}
	50%{top:0em}
	100%{top:0em;}
} 